@import 'styles/responsive';

.player-cover {
  position: absolute;
  transition: all 0.3s linear;
  z-index: -1;

  &.open.done {
    z-index: -1;
  }

  &.open {
    position: absolute;
    z-index: 3;
    width: 100% !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;

    img {
      border-radius: 0;
    }
  }

  img {
    object-fit: cover;
    border-radius: 10px;
    height: 100%;
    width: 100%;
  }
}

.player {
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
  background: #000;
  z-index: 10;
  overflow: hidden;

  @media @desktop {
    border-radius: 10px;
  }

  &.visible {
    opacity: 1;
  }

  &.ended {
    .video-stream {
      height: 30%;
      width: calc(100% - 40px);
      border-radius: 10px;
      box-sizing: border-box;
      z-index: 1;
      overflow: hidden;
      margin: 20px;
      video {
        width: 100%;
      }
    }
  }

  &.showSheet {
    .player__overlay {
      display: none;
    }

    .video-stream {
      height: 30%;
      width: calc(100% - 40px);
      border-radius: 10px;
      box-sizing: border-box;
      z-index: 1;
      overflow: hidden;
      margin: 20px;
      video {
        width: 100%;
      }
    }
  }

  &__video {
    height: 100%;
    display: flex;
    justify-content: center;
    .adaptive-video {
      height: 100%;
      width: 100%;
    }
  }

  .video-stream {
    height: 100%;
    width: 100%;

    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
      @media (orientation: landscape) {
        width: 100%;
      }
    }
  }

  &__top {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    padding-bottom: 10px;
  }

  &__overlay {
    position: absolute;
    z-index: 2;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  &__products {
    position: absolute;
    z-index: 100;
    bottom: 10px;
    left: 16px;

    li {
      display: block;
      margin-top: 10px;

      img {
        display: block;
        background: rgba(5, 10, 13, 0.6);
        border: 1px solid #ebf1f5;
        cursor: pointer;
        object-fit: cover;
        height: 128px;
        width: 110px;
        border-radius: 12px;
      }
    }
  }

  &__goback {
    height: 100%;
    width: 50%;
    position: absolute;
    top: 0;
    z-index: 0;
  }

  &__goforward {
    height: 100%;
    width: 50%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
  }

  .videoControls {
    position: absolute;
    z-index: 100;
    bottom: 10px;
    right: 20px;

    .control {
      margin-top: 14px;
    }
  }

  &__titleBar {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    z-index: 2;
    position: relative;
    height: 35px;

    .title {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      overflow: hidden;
      align-items: center;
      flex: 1;
      gap: 10px;
      margin-right: 10px;

      img {
        height: 35px;
        width: 35px;
        border-radius: 35px;
      }

      .text {
        color: #fcfdfe;
        font-weight: 700;
        font-size: 21px;
        line-height: 120%;

        letter-spacing: 0.01em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .icon {
        margin-top: -3px;
        svg {
          width: 13px;
        }
      }
    }

    > .icon,
    .arrow-down {
      color: #fcfdfe;
    }

    .icon.cart {
      display: flex;
      svg {
        width: 28px;
      }
    }

    .button.control {
      margin-left: 10px;
    }

    @media @desktop {
      .button-cart {
        display: none;
      }
    }

    @media @desktop {
      .button-cart {
        display: none;
      }
    }
  }
}
