.product-sheet {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    padding-bottom: 20px;
  }

  .button-close {
    margin-top: -10px;
    margin-right: -10px;
  }

  .product-details {
    min-height: fit-content;
  }
}
