@import 'styles/responsive';

.event-page {
  z-index: 0;
  position: relative;
  height: 100%;
  overflow: hidden;

  @media @desktop {
    border-radius: 10px;
  }

  > .button-cart {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 1;
  }

  &__top {
    height: 100%;
    position: relative;
    overflow: auto;
  }

  .hero {
    background-size: cover;

    height: 100%;
    width: 100%;
  }

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }

  &.typeImage {
    .content {
      bottom: 80px;
    }
  }

  .content {
    width: 100%;
    padding: 0px 24px;
    color: #fcfdfe;
    position: absolute;
    bottom: 60px;

    .creator {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 22px;
      line-height: 120%;
      letter-spacing: 0.01em;
      margin-bottom: 8px;

      img {
        height: 40px;
        width: 40px;
        object-fit: cover;
        border-radius: 40px;
        margin-right: 10px;
      }
    }
    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 35px;
      line-height: 120%;
    }
    p {
      margin-top: 16px;
      font-size: 17px;
      line-height: 150%;
      /* or 169% */
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      letter-spacing: 1%;
    }

    .button {
      margin-top: 24px;
      height: 54px;
      font-weight: 600;
      font-size: 17px;
      line-height: 120%;
      display: flex;
      align-items: center;
      justify-content: center;

      .loader {
        margin-top: -16px;
      }

      .icon svg {
        padding-top: 3px;
      }
    }

    .icon-button {
      cursor: pointer;
      margin-top: 40px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      animation: bounce 2.5s infinite;
      .icon svg {
        width: 15px;
        height: 15px;
      }
    }
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
