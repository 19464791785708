.terms {
  font-size: 14px;
  background: #fcfdfe;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: aktiv-grotesk, sans-serif;
    line-height: 1.28571429em;
    margin: calc(2rem - 0.14286em) 0 1rem;
    font-weight: 700;
    padding: 0;
  }

  p {
    margin: 0 0 1em;
    line-height: 1.4285em;
  }

  h1 {
    margin-top: 50px;
    font-size: 28px;
  }

  h2 {
    font-size: 22px;
  }

  p {
    font-size: 18px;
  }

  pre {
    white-space: pre-wrap;
  }

  pre,
  code {
    font-size: 18px;
    font-family: inherit;
    line-height: 1.4285em;
  }
}
