.video-stream {
  position: relative;
  background-color: #000;
  object-fit: cover;

  &__error {
    position: absolute;
    z-index: 1;
    top: 15px;
    left: 15px;
    background: #000;
    color: #e20000;
    font-weight: bold;
    padding: 0.3em 0.9em;
    border-radius: 3px;
  }

  &__buffering {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 100%;
  }

  &__video {
    display: block;
    height: 100%;

    &--disabled {
      opacity: 0.5;
    }
  }
}
