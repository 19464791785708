.landing {
  #unlock-button,
  button.landing-button {
    background-color: #fcfdfe !important;
    color: #000 !important;
    font-weight: 600 !important;
    font-size: 18px;
    border-radius: 10em;
    padding: 0.78571429em 1.5em;
    line-height: 1.2em;
    text-transform: none;
  }

  #unlock-button:hover,
  button.landing-button:hover {
    opacity: 0.9;
  }

  video {
    outline: none;
  }

  video::-webkit-media-controls-enclosure {
    visibility: visible;
  }

  .logo {
    width: 60%;
  }

  .ui.input {
    position: relative;
    font-weight: 400;
    font-style: normal;
    display: inline-flex;
    color: rgba(0, 0, 0, 0.87);

    > input {
      padding: 0.67857143em 1em;
      line-height: 1.21428571em;
    }
  }

  .ui.action .landing-button {
    border-radius: 0;
    border-top-right-radius: 10em;
    border-bottom-right-radius: 10em;
    padding-left: 1em;
    padding-right: 1.5em;
    font-size: 18px;
  }

  .ui.action.input:not([class*='left action']) > input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right-color: transparent !important;
  }
}

@media only screen and (max-width: 600px) {
  .landing .logo {
    width: 90%;
  }
}
