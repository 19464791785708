.checkout-account {
  padding-top: 20px;
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.408px;
    margin-bottom: 20px;
  }
}
