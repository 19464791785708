.checkout-card {
  padding-top: 20px;

  .loader {
    text-align: center;
  }
  .button {
    margin-top: 20px;
  }

  .center {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }

  .paymentMethodItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid #ebf1f5;
    padding-top: 1em;
    padding-bottom: 1em;

    &:last-of-type {
      margin-bottom: 2em;
    }

    &__icon {
      margin-right: 1em;
      svg {
        width: 25px;
        height: 25px;
      }
    }
    &__checkmark {
      flex: 1;
      display: flex;
      justify-content: flex-end;

      margin-right: 1em;
      height: 20px;

      svg {
        width: 20px;
        height: 20px;
        color: #3f82aa;
      }
    }

    &__card {
      font-size: 16px;
      font-weight: 400;
      margin-right: 4px;
    }
    &__number {
      font-size: 16px;
      color: #8a8a8f;
    }
  }

  .StripeElement {
    margin-right: 10px;
  }

  form {
    margin-top: 1em;
  }
}
