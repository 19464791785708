@import '../../styles/responsive';

.layout > .group {

  &.fixed {
    flex: none;
  }

  &.grow {
    flex: auto;
    min-width: 0;
    min-height: 0;
  }

  &.flex {
    display: flex;
  }

}
