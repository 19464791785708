@import 'styles/responsive';

.FloatingCartButton {
  position: fixed;
  bottom: 20px;
  right: 20px;

  @media @desktop {
    display: none;
  }
}
