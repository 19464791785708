.productCard {
  cursor: pointer;
  margin-bottom: 10px;
  &__image {
    border: 1px solid #ccc;
    object-fit: cover;
    width: 212px;
    height: 212px;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  &__name {
    margin: 0;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.408px;
    color: #000000;
  }

  &__price {
    margin-top: 4px;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    &.original {
      text-decoration: line-through;
      color: #9b9b9b;
    }
  }
}
