@import './vars';
@import './responsive';

.upper {
  text-transform: uppercase;
}

.xs {
  font-size: 12px;
}

.sm {
  font-size: 14px;
}

.lg {
  font-size: 24px;
  line-height: 1.2;
}

.xl {
  font-size: 32px;
  line-height: 1.2;
}

.xxl {
  font-size: 54px;
  line-height: 1.2;
}

.faded {
  opacity: 0.5;
}

.pushed {
  @media @desktop {
    margin-left: 8vw;
  }
}

.bold {
  font-weight: bold;
}

.dark {
  color: #fcfdfe;
  -webkit-font-smoothing: antialiased;
}

.narrow {
  max-width: 360px;
  margin: 0 auto;
}

.bg-black {
  background-color: @bgBlack;
}

.bg-beige {
  background-color: @bgBeige;
}

.link {
  color: @linkColor !important;
  cursor: pointer;
}

@media @mobile {
  .desktop {
    display: none !important;
  }
}

@media @tiny {
  .desktop {
    display: none !important;
  }
}

@media @tablet {
  .mobile {
    display: none !important;
  }
}

@media @desktop {
  .mobile {
    display: none !important;
  }
}
