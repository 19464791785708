.cart-total {
  border-top: 1px solid #e8e8ed;
  padding-top: 15px;
  padding-bottom: 15px;

  display: block;

  &.loading {
    justify-content: center;
    display: flex;
  }

  .desposit {
    margin-top: 24px;
    p {
      margin-top: 16px;
      font-size: 12px;
      line-height: 16px;
      color: #8a8a8f;
      margin-bottom: 4px;
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #8a8a8f;
    letter-spacing: -0.408px;

    .value {
      font-weight: 500;
      color: #000000;
    }

    &.primary {
      .label {
        color: #000000;
      }
    }

    &.secondary {
      .value {
        color: #8a8a8f;
      }
    }
  }
}
