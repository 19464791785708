.checkout-shipping {
  padding-top: 20px;
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.408px;
    margin-bottom: 20px;
  }

  &__line1 {
    padding: 0;
    .input__container {
      padding: 0;
    }
    label {
      margin-top: 1em;
      margin-left: 1.6em;
    }
    input {
      padding-left: 1em;
      padding-bottom: 0.5em;
    }
  }

  .pac-target-input {
  }
}

.pac-container {
  border-radius: 10px;
  margin-top: 8px;
  .pac-item {
    padding: 4px 2em;
    border-bottom: 0.5px solid #d9d9d9;
    border-top: 0;
    &:hover {
      background-color: #f5f5f5;
    }
  }

  .pac-icon {
    display: none;
  }
}

.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

  mix-blend-mode: luminosity;
  text-align: right;
  height: 34px;
  background-position: left;
  margin-left: 1.5em;
}
