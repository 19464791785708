.timeline {
  display: flex;
  flex-direction: row;
  gap: 4px;
  width: 100%;
  padding: 0px 20px;
  padding-top: 10px;

  &__chapter {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 3px;
    height: 6px;
    flex: 1;
    margin-top: 4px;
  }

  &__progress {
    border-radius: 10px;
    height: 100%;
    background: #fcfdfe;
    transition: width 0.4s linear;
    will-change: width;
  }
}
