.notification {
  cursor: pointer;
  position: fixed;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  padding: 14px 40px;
  background: #fcfdfe;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  width: max-content;
  z-index: 1000;

  h2 {
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.408px;
  }
}
