.cart-details {
  padding-bottom: 20px;
  user-select: none;

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &__empty {
    .icon svg {
      height: 35px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin: auto;
    margin-top: 80px;
    h2 {
      margin-top: 10px;
      font-weight: 600;
      font-size: 17px;
      line-height: 22px;
      /* identical to box height, or 129% */
      letter-spacing: -0.5px;
      margin-bottom: 5px;
    }

    p {
      text-align: center;
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #ccc;

    margin-bottom: 20px;
    margin-top: 20px;
    padding-bottom: 20px;
    &:last-child {
      border-bottom: none;
      padding-bottom: 0px;
    }

    .row {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: row;

      margin-bottom: 5px;
    }

    &-price {
      font-size: 14px;
      line-height: 21px;
    }
  }

  &__item-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__item-description {
    flex: 1;
    margin-right: 20px;
    min-height: 110px;
    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 22px;
    }
    h3 {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 22px;
      margin-bottom: 0.5em;
    }
  }

  &__item_action {
    flex: 0;
    padding-right: 5px;
    svg {
      width: 14px;
    }
  }

  &__item-image {
    display: flex;
    flex: 0;
    width: 90px;
    margin-right: 15px;

    img {
      border: 1px solid #ccc;
      border-radius: 10px;
      object-fit: cover;
      height: 90px;
      width: 90px;
    }
  }

  &__item-qty-label {
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    color: #000000;
  }

  &__item_qty {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    justify-content: space-between;

    .spacer {
      width: 124px;
      height: 20px;
    }

    .group {
      background: #eeeeee;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      padding: 3px 10px;

      button {
        .icon {
          display: flex;
          svg {
            width: 17px;
          }
        }
      }

      button[disabled] {
        cursor: not-allowed;
        opacity: 0.2;
      }

      .divider {
        margin: 0 5px;
        background: #d6d6d8;
        min-height: 100%;
        width: 1px;
      }
    }
  }
}
