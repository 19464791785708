.cart-actions {
  overflow: hidden;
  height: 100%;
  position: absolute;
  z-index: 1000;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  &__overlay {
    width: 100%;
    flex: 1;
    cursor: pointer;
  }
  &__menu {
    flex: 0;
    justify-content: flex-end;
    border-radius: 8px 8px 10px 10px;
    background: #fcfdfe;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }

  &__item {
    font-weight: 500;
    font-size: 17px;
    line-height: 17px;
    letter-spacing: -0.408px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &.negative {
      color: #ff3b30;
    }

    .icon svg {
      line-height: 18px;
      height: 18px;
      padding-right: 20px;
    }
  }
}

//easeInOut
