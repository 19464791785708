.button-cart {
  color: #000;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  background: #fcfdfe;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05), 0px 5px 25px rgba(0, 0, 0, 0.1);
  border-radius: 20px;

  padding: 8px 12px 8px 12px;
  font-size: 17px;
  font-weight: 700;

  &__count {
    margin-left: 2px;
    margin-right: 2px;
  }

  > .icon svg {
    height: 17px;
    margin-top: 4px;
    color: #000;
    margin-right: 3px;
  }
}
