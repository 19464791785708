.prompt {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1000;
  top: 0;
  bottom: 0;
  background: rgba(242, 242, 242, 0.4);
  display: flex;
  justify-content: center;
  //backdrop-filter: blur(22px);

  /* Note: backdrop-filter has minimal browser support */

  &__container {
    margin-top: 20%;
    border-radius: 14px;
    z-index: 100;
    width: 270px;
    background: #fcfdfe;
    height: fit-content;
  }

  h2 {
    padding: 10px;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.408px;
    color: #000000;
  }
  p {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.078px;
    color: #000000;
  }
  .divider {
    margin-top: 20px;
    margin-bottom: 10px;
    height: 0.5px;
    width: 100%;
    background: rgba(60, 60, 67, 0.36);
  }
  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    cursor: pointer;
  }

  button {
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    cursor: pointer;

    text-align: center;
    letter-spacing: -0.408px;
    color: #007aff;
  }
}
