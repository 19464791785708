.button {
  font-weight: 700;
  border-radius: 8px;
  padding: 1.5em 3em;
  cursor: pointer;
  background-color: #fcfdfe;
  color: #000;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  border-radius: 8px;
  padding: 0.78571429em 1.5em;
  line-height: 1.2em;
  text-align: center;

  &.control {
    height: 40px;
    width: 40px;
    background: rgba(5, 10, 13, 0.6);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    .icon {
      display: inline-flex;
      height: 25px;
      width: 25px;
      color: #fcfdfe;
    }
  }

  &.fluid {
    width: 100%;
  }

  &.primary {
    background: #000;
    color: #fcfdfe;
  }

  &.rounded {
    border-radius: 10em;
  }

  &.disabled {
    background: #ccc;
    cursor: auto;
  }

  &.loading {
    .loader {
      height: 24px;
      margin-top: -40px;
    }
  }

  &.icon {
    .icon {
      vertical-align: middle;
      margin-right: 0.5em;
      svg {
        height: 20px;
      }
    }
  }
}
