.landing-layout {
  background-color: #000 !important;
  color: #fcfdfe;
  font-family: aktiv-grotesk, sans-serif;
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
