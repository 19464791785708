@import 'styles/responsive.less';

.event-banner {
  color: #fcfdfe;
  background: #000;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, aktiv-grotesk, sans-serif;

  .only-desktop {
    display: none;
    @media @desktop {
      display: block;
    }
  }

  .only-mobile {
    display: none;
    @media @mobile {
      display: block;
    }
  }

  .banner {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 22px;
    /* or 116% */

    letter-spacing: 0.35px;
    justify-content: space-between;
    background: #1d1d1f;
    padding: 16px 25px;
    align-items: flex-start;

    &__text {
      width: 264px;
    }
    &__icon {
      padding-top: 16px;
      font-size: 40px;
    }
  }

  .container {
    padding: 24px;
    .message {
      text-align: center;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .event-item {
    img {
      border-radius: 20px;
      object-fit: cover;
      width: 100%;
      aspect-ratio: 18 / 12;
    }

    .creator {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        height: 40px;
        width: 40px;
        object-fit: cover;
        border-radius: 40px;
        margin-right: 10px;
      }
    }

    h2 {
      margin-top: 20px;
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 30px;
    }

    p {
      margin-top: 4px;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.374px;
    }
  }

  .bottom-logo {
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon {
      width: 106px;
      height: 38spx;
      color: #b4b4b4;
    }

    div {
      margin: 0;
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      letter-spacing: -0.408px;
      color: rgba(255, 255, 255, 0.7);
    }
  }
}
