@import 'styles/responsive.less';

.contact {
  background-color: #000;
  font-family: aktiv-grotesk, sans-serif;
  font-size: 17px;
  font-weight: 400;
  display: flex;
  padding: 10px;
  padding-top: 50px;
  padding-bottom: 100px;
  width: 100vw;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media @desktop {
    padding-top: 200px;
  }

  .logo {
    padding-right: 0px;
    padding-left: 0px;

    width: 100%;
    @media @desktop {
      width: 400px;
    }
  }

  h1 {
    margin-bottom: 20px;
    font-weight: 700;
    font-family: aktiv-grotesk, sans-serif;
    color: #fcfdfe;
    font-size: 30px;
    line-height: 30px;
    text-align: center;

    @media @desktop {
      font-size: 50px;
      line-height: 60px;
      font-weight: 700;
    }
  }

  p {
    color: #cecece;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    max-width: 335px;
    padding-right: 40px;
    padding-left: 40px;
    @media @desktop {
      font-size: 21px;
      font-weight: 400;
      max-width: 100%;
    }
  }

  .address {
    margin-top: 10px;
    text-align: center;
    line-height: 30px;
    a {
      color: #fcfdfe;
    }
  }
  h3 {
    margin-top: 60px;
    font-size: 21px;
    display: block;
    font-weight: bold;
  }
}
