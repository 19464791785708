@import './vars';

[data-rsbs-header] {
  padding: 12px 15px !important;
  box-shadow: none !important;
}

body {
  min-height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

body,
html {
  height: 100%;
}

main {
  min-height: 60vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1;
}

em {
  font-family: @headerFont;
}

p a {
  color: @linkColor;
}
