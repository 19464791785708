// https://stackoverflow.com/questions/57195620/css-autocomplete-font-size

input:-webkit-autofill {
  &,
  &:hover,
  &:focus,
  &:active {
    transition: background-color 5000s ease-in-out 0s,color 5000s ease-in-out 0s;
  }
  &::first-line {
    font-size: @baseFontSize;
    font-family: @bodyFont;
  }

}
