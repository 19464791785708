@import '../../styles/responsive';

.layout > .spacer {
  flex-basis: 5%;

  &.mini   { flex-basis: .5%; }
  &.tiny   { flex-basis: 1%; }
  &.small  { flex-basis: 2%; }
  &.large  { flex-basis: 10%; }
  &.big    { flex-basis: 20%; }
  &.huge   { flex-basis: 50%; }

}
