@import 'styles/responsive.less';

.waitlist {
  background-color: #000;
  font-family: aktiv-grotesk, sans-serif;
  font-size: 17px;
  font-weight: 400;
  display: flex;
  padding: 10px;
  padding-top: 50px;
  padding-bottom: 100px;
  width: 100vw;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media @desktop {
    padding-top: 200px;
  }

  .logo {
    padding-right: 0px;
    padding-left: 0px;

    width: 100%;
    @media @desktop {
      width: 400px;
    }
  }

  h1 {
    margin-bottom: 20px;
    font-weight: 700;
    font-family: aktiv-grotesk, sans-serif;
    color: #fcfdfe;
    font-size: 30px;
    line-height: 30px;
    text-align: center;

    @media @desktop {
      font-size: 50px;
      line-height: 60px;
      font-weight: 700;
    }
  }

  p {
    color: #cecece;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    max-width: 335px;
    padding-right: 40px;
    padding-left: 40px;
    @media @desktop {
      font-size: 21px;
      font-weight: 400;
      max-width: 100%;
    }
  }

  form {
    margin-top: 50px;
    text-align: center;
    flex-direction: column;
    display: flex;

    padding-right: 20px;
    padding-left: 20px;

    .field-label {
      margin-bottom: 15px;
      font-size: 21px;
      display: block;
      font-weight: bold;
    }

    .text-field {
      position: relative;
      display: inline-block;
      width: 100%;
      color: #999;

      border-radius: 10px;

      font-size: 21px;
      line-height: 21px;
      text-align: center;
      height: 38px;
      padding: 8px 12px;
      margin-bottom: 10px;
      vertical-align: middle;
      border: 1px solid #cccccc;
      &:focus {
        border-color: #3898ec;
        outline: 0;
      }

      @media @desktop {
        width: 450px;
      }
    }

    input::placeholder {
      color: #999;
      opacity: 1;
    }

    .submit-button {
      -webkit-appearance: button;
      width: 100%;
      max-width: 550px;
      margin-top: 10px;
      border-radius: 10px;
      background-color: #fcfdfe;
      color: #000;
      font-size: 21px;
      display: inline-block;
      border: 0;
      line-height: 38px;
      text-decoration: none;
      cursor: pointer;
      display: inline-block;
      @media @desktop {
      }
    }
  }

  .success-message {
    margin-top: 60px;
    color: #fcfdfe;
    font-size: 21px;
    font-weight: 700;
  }
}
