.button-close {
  cursor: pointer;
  background: rgba(116, 116, 128, 0.08);
  backdrop-filter: blur(54.3656px);
  height: 24px;
  width: 24px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 12px;
  }
}
