@import '../../styles/responsive';

@spacing-xxs: 8px;
@spacing-xs: 16px;
@spacing-s: 32px;
@spacing-m: 64px;
@spacing-l: 128px;
@spacing-xl: 256px;

.spacer(@height) {
  height: @height;
}

.spacer {
  &.size {
    &.xxs {
      .spacer(@spacing-xxs);
    }
    &.xs {
      .spacer(@spacing-xs);
    }
    &.s {
      .spacer(@spacing-s);
    }
    &.m {
      .spacer(@spacing-m);
    }
    &.l {
      .spacer(@spacing-l);
    }
    &.xl {
      .spacer(@spacing-xl);
    }
  }
}
