.select-overlay {
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    letter-spacing: 0.35px;
    color: #000000;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
  }
  &__header-close {
    svg {
      height: 20px;
      width: 20px;
    }
  }
  &__options {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #ccc;
    height: 100%;
    overflow: auto;
  }

  &__option {
    display: flex;

    padding: 20px;
    &:hover {
      background-color: #f5f5f5;
    }
    border-bottom: 1px solid #f2f2f7;
    &.disabled {
      background: #f2f2f7;
      color: rgba(0, 0, 0, 0.4);
    }
  }
}

.select {
  button {
    font-size: 17px;
    line-height: 22px;
    font-weight: 600;
    border: 2px solid #e8e8ed;
    border-radius: 8px;
  }
  .icon svg {
    margin-left: 5px;
    width: 14px;
  }
}
