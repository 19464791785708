@import 'styles/responsive';

.screen {
  height: 100%;
  canvas {
    display: none;
  }

  @media @desktop {
    canvas {
      display: block;
    }
    &__background {
      position: absolute;
      width: 100%;
      height: 100%;
      transform: scale(1.2);
      filter: blur(80px);
      opacity: 0.75;
      z-index: -1;
    }

    display: flex;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
    align-items: center;

    &__container {
      position: relative;
      max-height: min(83vh, 812px);
      aspect-ratio: 9 / 16;

      #screen-content {
        max-height: min(83vh, 812px);
        height: 812px;
        aspect-ratio: 9 / 16;

        border-radius: 10px;
      }

      .react-modal-sheet-container {
        border-radius: 8px !important;
      }
    }

    &__next,
    &__prev {
      display: block !important;
    }
  }

  &__container {
    height: 100%;
  }

  #screen-content {
    height: 100%;
  }

  &__next,
  &__prev {
    display: block;
    color: #fcfdfe;
    background: blue;
  }
}
