@import 'styles/responsive';

.imageEvent {
  background: #fcfdfe;
  z-index: 0;
  position: relative;
  min-height: 100%;
  padding-bottom: 5%;
  padding: 24px;

  &__description {
    font-weight: 400;
    font-size: 17px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 20px;

    line-height: 26px;
    letter-spacing: 0.01em;

    &.show-all {
      -webkit-line-clamp: initial;
    }
  }

  .products {
    .productCard {
      .productCard__image {
        width: 96px;
        height: 128px;
        margin-right: 10px;
      }
      display: flex;
      .productCard__stats {
      }
    }
  }
}
