.sheet-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 10px;
  width: 100%;

  &__container {
    padding-top: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .spacer {
      flex: 0;
      display: flex;
      min-width: 24px;

      &.right {
        justify-content: flex-end;
      }
    }
  }

  &__indicator {
    width: 25px;
    height: 4px;
    border-radius: 99px;
    background-color: rgba(5, 10, 13, 0.4);
  }

  h2 {
    flex: 1;
    text-align: center;
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.40799999237060547px;
    text-transform: capitalize;
  }
}
