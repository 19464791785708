.auth-screen {
  background: #fcfdfe;
  min-height: 100vh;
  display: flex;

  .link-group {
    margin-top: 1em;
  }

  .message,
  form {
    width: 342px;
  }

  .message {
    background-color: #f3f3f3;
    font-size: 14px;
    width: 342px;
  }

  form .field.no-border {
    border: 0;
    padding: 0.5em;
  }

  .message {
    border-radius: 8px;
    padding: 1em;
    color: #ff3d00 !important;
    text-align: center;
  }

  .button {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 8px;
    padding: 1.5em 3em;
    cursor: pointer;
  }
}
