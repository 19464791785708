@import 'styles/responsive';

.topNav {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100vw;
  padding: 10px 10px;
  height: 60.5px;
  background: rgba(5, 10, 13, 0.3);

  @media @desktop {
    position: fixed;
    padding: 36px;
    background: none;
  }

  @media @tablet {
    padding: 20px;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &__logo {
    height: 30px;
  }
}
